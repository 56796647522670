import * as React from 'react';
import cn from 'classnames';
import css from './Field.scss';
import Grid from 'styleguide/components/Grid/Grid';

const Heading = ({ heading, headingClassName }) => {
  if (!heading) {
    return null;
  }

  if (typeof heading === 'string') {
    return (
      <Grid.Col sm={12} md={12} className={headingClassName}>
        <p className={cn(css.formHeading)}>{heading}</p>
      </Grid.Col>
    );
  }

  return heading; // react node
};

interface Props {
  children?: React.ReactNodeArray;
  heading?: string;
  className?: string;
  leftFieldClassName?: string;
  rightFieldClassName?: string;
  headingClassName?: string;
}

const TwoColumnFormField = ({
  heading,
  headingClassName,
  leftFieldClassName,
  rightFieldClassName,
  children,
  ...props
}: Props) => (
  <Grid.Row className={cn(css.zeroMarginPadding, props.className)}>
    <Heading heading={heading} headingClassName={headingClassName} />
    <Grid.Col sm={12} md={6} className={cn(leftFieldClassName)}>
      {children[0]}
    </Grid.Col>
    <Grid.Col sm={12} md={6} className={cn(rightFieldClassName)}>
      {children[1]}
    </Grid.Col>
  </Grid.Row>
);

export default TwoColumnFormField;
