import apiCall from 'app/libs/utils/api/apiCall';
import { OperatorResponse } from '../types';
import { SharedErrors } from 'libs/utils/api/types';

// /users
export const settingsScope = (path?) => `/users${path || ''}`;

// /users/:id
export const updateSettings = user => {
  const url = settingsScope(`/${user.id}`);
  return apiCall.put<OperatorResponse, SharedErrors>({ url, data: { user }, unscoped: true });
};

export const acknowledgeAddressValidation = user => {
  const url = settingsScope(`/acknowledge_address_validation/`);
  return apiCall.put<OperatorResponse, SharedErrors>({ url, data: { user }, unscoped: true });
};
