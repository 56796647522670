import * as React from 'react';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import cn from 'classnames';
import { Label } from 'styleguide/components/forms';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import FormikFieldWrapper from '../FormikFieldWrapper/FormikFieldWrapper';

interface Props {
  prefix: string;
}

const appendName = (prefix, name) => {
  if (prefix === '') {
    return name;
  }
  return `${prefix}.${name}`;
};

const NotificationsForm = ({ prefix }: Props) => (
  <TwoColumnFormField className="!mt-2 flex" heading="SMS notifications">
    <FieldWrapper
      className="mt-[-18px] mb-3 md:mb-0"
      Label={<Label placement="left">Send project updates to your mobile device</Label>}
      Input={
        <RadioTabs name={appendName(prefix, 'sendSmsNotifications')}>
          <FormikFieldWrapper
            name={appendName(prefix, 'sendSmsNotifications')}
            value
            labelComponent="Yes"
            componentType="radio"
          />
          <FormikFieldWrapper
            name={appendName(prefix, 'sendSmsNotifications')}
            value={false}
            labelComponent="No"
            componentType="radio"
          />
        </RadioTabs>
      }
    />
    <FormikFieldWrapper
      inPlaceError
      name={appendName(prefix, 'smsPhone')}
      componentType="input"
      labelComponent="Mobile Number"
    />

    <p className={cn('text-sm leading-5 -md:mb-12 -md:mt-4', 'm-0 p-0')}>
      Enable updates about the status of your project to a mobile device.
    </p>
  </TwoColumnFormField>
);

export default NotificationsForm;
